import 'index.scss'
import App from 'App'
import React from 'react'
import store from './store'
import ReactDOM from 'react-dom'
import {dispatch} from 'functions'
import {Provider} from 'react-redux'
import reportWebVitals from 'reportWebVitals'
import {BrowserRouter} from 'react-router-dom'

const {warn: consoleWarn, error: consoleError} = console
console.warn = (...data) => {
    const [warn] = data
    if (warn.includes('is beyond boundary when transfer to integer, the results may not be accurate')) {
        return
    }
    consoleWarn(...data)
}
console.error = (...data) => {
    const [warn] = data
    if (warn.includes('Warning: Each child in a list should have a unique "key" prop.') ||
        warn.includes('Warning: ReactDOM.render is no longer supported in React 18.')
    ) {
        return
    }
    consoleError(...data)
}
const fontSize = () => {
    const {clientWidth} = document.body
    dispatch(store, {isPhoneClientWidth: clientWidth <= 1024})
    document.documentElement.style.fontSize = clientWidth /
        // 关联index.scss的$fullWidthSize的30rem
        30 + 'px'
}
fontSize()
window.onresize = fontSize
// const App = require(sessionStorage.getItem('dev') ? 'App1' : 'App').default
ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
