export default {
    "address": "0x589F0171783738B7491d2E5aa3aA00E9034C0888",
    "abi": [{
        "inputs": [{
            "internalType": "address",
            "name": "initialOwner",
            "type": "address"
        }, {
            "internalType": "contract IPoolManager",
            "name": "_manager",
            "type": "address"
        }, {
            "internalType": "contract IMirrorTokenManager",
            "name": "_mirrorTokenManager",
            "type": "address"
        }, {"internalType": "contract IMarginPositionManager", "name": "_marginPositionManager", "type": "address"}],
        "stateMutability": "nonpayable",
        "type": "constructor"
    }, {"inputs": [], "name": "AddLiquidityDirectToHook", "type": "error"}, {
        "inputs": [],
        "name": "BalanceOverflow",
        "type": "error"
    }, {"inputs": [], "name": "HookNotImplemented", "type": "error"}, {
        "inputs": [],
        "name": "IncorrectSwapAmount",
        "type": "error"
    }, {"inputs": [], "name": "InsufficientLiquidityBurnt", "type": "error"}, {
        "inputs": [],
        "name": "InsufficientLiquidityMinted",
        "type": "error"
    }, {"inputs": [], "name": "InvalidInitialization", "type": "error"}, {
        "inputs": [],
        "name": "InvalidPool",
        "type": "error"
    }, {"inputs": [], "name": "LockFailure", "type": "error"}, {
        "inputs": [],
        "name": "NotFactory",
        "type": "error"
    }, {"inputs": [], "name": "NotPoolManager", "type": "error"}, {
        "inputs": [],
        "name": "NotPositionManager",
        "type": "error"
    }, {"inputs": [], "name": "NotSelf", "type": "error"}, {
        "inputs": [],
        "name": "PairNotExists",
        "type": "error"
    }, {"inputs": [], "name": "PoolAlreadyInitialized", "type": "error"}, {
        "anonymous": false,
        "inputs": [{"indexed": true, "internalType": "address", "name": "owner", "type": "address"}, {
            "indexed": true,
            "internalType": "address",
            "name": "spender",
            "type": "address"
        }, {"indexed": true, "internalType": "uint256", "name": "id", "type": "uint256"}, {
            "indexed": false,
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
        }],
        "name": "Approval",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{"indexed": true, "internalType": "address", "name": "sender", "type": "address"}, {
            "indexed": false,
            "internalType": "uint256",
            "name": "poolId",
            "type": "uint256"
        }, {"indexed": false, "internalType": "uint256", "name": "liquidity", "type": "uint256"}, {
            "indexed": false,
            "internalType": "uint256",
            "name": "amount0",
            "type": "uint256"
        }, {"indexed": false, "internalType": "uint256", "name": "amount1", "type": "uint256"}],
        "name": "Burn",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{"indexed": true, "internalType": "address", "name": "sender", "type": "address"}, {
            "indexed": false,
            "internalType": "uint256",
            "name": "poolId",
            "type": "uint256"
        }, {"indexed": false, "internalType": "uint256", "name": "liquidity", "type": "uint256"}, {
            "indexed": false,
            "internalType": "uint256",
            "name": "amount0",
            "type": "uint256"
        }, {"indexed": false, "internalType": "uint256", "name": "amount1", "type": "uint256"}, {
            "indexed": true,
            "internalType": "address",
            "name": "to",
            "type": "address"
        }],
        "name": "Mint",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{"indexed": true, "internalType": "address", "name": "owner", "type": "address"}, {
            "indexed": true,
            "internalType": "address",
            "name": "operator",
            "type": "address"
        }, {"indexed": false, "internalType": "bool", "name": "approved", "type": "bool"}],
        "name": "OperatorSet",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{"indexed": true, "internalType": "address", "name": "user", "type": "address"}, {
            "indexed": true,
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
        }],
        "name": "OwnershipTransferred",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{
            "indexed": false,
            "internalType": "uint256",
            "name": "amountIn",
            "type": "uint256"
        }, {"indexed": false, "internalType": "uint256", "name": "amountOut", "type": "uint256"}],
        "name": "Swap",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{
            "indexed": false,
            "internalType": "uint256",
            "name": "reserve0",
            "type": "uint256"
        }, {"indexed": false, "internalType": "uint256", "name": "reserve1", "type": "uint256"}, {
            "indexed": false,
            "internalType": "uint256",
            "name": "mirrorReserve0",
            "type": "uint256"
        }, {"indexed": false, "internalType": "uint256", "name": "mirrorReserve1", "type": "uint256"}],
        "name": "Sync",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{"indexed": false, "internalType": "address", "name": "caller", "type": "address"}, {
            "indexed": true,
            "internalType": "address",
            "name": "from",
            "type": "address"
        }, {"indexed": true, "internalType": "address", "name": "to", "type": "address"}, {
            "indexed": true,
            "internalType": "uint256",
            "name": "id",
            "type": "uint256"
        }, {"indexed": false, "internalType": "uint256", "name": "amount", "type": "uint256"}],
        "name": "Transfer",
        "type": "event"
    }, {
        "inputs": [],
        "name": "MINIMUM_LIQUIDITY",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [],
        "name": "ONE_BILLION",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [],
        "name": "ONE_MILLION",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [],
        "name": "SQRT_RATIO_1_1",
        "outputs": [{"internalType": "uint160", "name": "", "type": "uint160"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [],
        "name": "YEAR_SECONDS",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{
            "components": [{
                "internalType": "PoolId",
                "name": "poolId",
                "type": "bytes32"
            }, {"internalType": "uint256", "name": "amount0", "type": "uint256"}, {
                "internalType": "uint256",
                "name": "amount1",
                "type": "uint256"
            }, {"internalType": "uint256", "name": "tickLower", "type": "uint256"}, {
                "internalType": "uint256",
                "name": "tickUpper",
                "type": "uint256"
            }, {"internalType": "address", "name": "to", "type": "address"}, {
                "internalType": "uint256",
                "name": "deadline",
                "type": "uint256"
            }], "internalType": "struct AddLiquidityParams", "name": "params", "type": "tuple"
        }],
        "name": "addLiquidity",
        "outputs": [{"internalType": "uint256", "name": "liquidity", "type": "uint256"}],
        "stateMutability": "payable",
        "type": "function"
    }, {
        "inputs": [{
            "internalType": "address",
            "name": "",
            "type": "address"
        }, {
            "components": [{
                "internalType": "Currency",
                "name": "currency0",
                "type": "address"
            }, {"internalType": "Currency", "name": "currency1", "type": "address"}, {
                "internalType": "uint24",
                "name": "fee",
                "type": "uint24"
            }, {"internalType": "int24", "name": "tickSpacing", "type": "int24"}, {
                "internalType": "contract IHooks",
                "name": "hooks",
                "type": "address"
            }], "internalType": "struct PoolKey", "name": "", "type": "tuple"
        }, {
            "components": [{"internalType": "int24", "name": "tickLower", "type": "int24"}, {
                "internalType": "int24",
                "name": "tickUpper",
                "type": "int24"
            }, {"internalType": "int256", "name": "liquidityDelta", "type": "int256"}, {
                "internalType": "bytes32",
                "name": "salt",
                "type": "bytes32"
            }], "internalType": "struct IPoolManager.ModifyLiquidityParams", "name": "", "type": "tuple"
        }, {"internalType": "BalanceDelta", "name": "", "type": "int256"}, {
            "internalType": "BalanceDelta",
            "name": "",
            "type": "int256"
        }, {"internalType": "bytes", "name": "", "type": "bytes"}],
        "name": "afterAddLiquidity",
        "outputs": [{"internalType": "bytes4", "name": "", "type": "bytes4"}, {
            "internalType": "BalanceDelta",
            "name": "",
            "type": "int256"
        }],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{
            "internalType": "address",
            "name": "",
            "type": "address"
        }, {
            "components": [{
                "internalType": "Currency",
                "name": "currency0",
                "type": "address"
            }, {"internalType": "Currency", "name": "currency1", "type": "address"}, {
                "internalType": "uint24",
                "name": "fee",
                "type": "uint24"
            }, {"internalType": "int24", "name": "tickSpacing", "type": "int24"}, {
                "internalType": "contract IHooks",
                "name": "hooks",
                "type": "address"
            }], "internalType": "struct PoolKey", "name": "", "type": "tuple"
        }, {"internalType": "uint256", "name": "", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }, {"internalType": "bytes", "name": "", "type": "bytes"}],
        "name": "afterDonate",
        "outputs": [{"internalType": "bytes4", "name": "", "type": "bytes4"}],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{
            "internalType": "address",
            "name": "",
            "type": "address"
        }, {
            "components": [{
                "internalType": "Currency",
                "name": "currency0",
                "type": "address"
            }, {"internalType": "Currency", "name": "currency1", "type": "address"}, {
                "internalType": "uint24",
                "name": "fee",
                "type": "uint24"
            }, {"internalType": "int24", "name": "tickSpacing", "type": "int24"}, {
                "internalType": "contract IHooks",
                "name": "hooks",
                "type": "address"
            }], "internalType": "struct PoolKey", "name": "", "type": "tuple"
        }, {"internalType": "uint160", "name": "", "type": "uint160"}, {
            "internalType": "int24",
            "name": "",
            "type": "int24"
        }],
        "name": "afterInitialize",
        "outputs": [{"internalType": "bytes4", "name": "", "type": "bytes4"}],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{
            "internalType": "address",
            "name": "",
            "type": "address"
        }, {
            "components": [{
                "internalType": "Currency",
                "name": "currency0",
                "type": "address"
            }, {"internalType": "Currency", "name": "currency1", "type": "address"}, {
                "internalType": "uint24",
                "name": "fee",
                "type": "uint24"
            }, {"internalType": "int24", "name": "tickSpacing", "type": "int24"}, {
                "internalType": "contract IHooks",
                "name": "hooks",
                "type": "address"
            }], "internalType": "struct PoolKey", "name": "", "type": "tuple"
        }, {
            "components": [{"internalType": "int24", "name": "tickLower", "type": "int24"}, {
                "internalType": "int24",
                "name": "tickUpper",
                "type": "int24"
            }, {"internalType": "int256", "name": "liquidityDelta", "type": "int256"}, {
                "internalType": "bytes32",
                "name": "salt",
                "type": "bytes32"
            }], "internalType": "struct IPoolManager.ModifyLiquidityParams", "name": "", "type": "tuple"
        }, {"internalType": "BalanceDelta", "name": "", "type": "int256"}, {
            "internalType": "BalanceDelta",
            "name": "",
            "type": "int256"
        }, {"internalType": "bytes", "name": "", "type": "bytes"}],
        "name": "afterRemoveLiquidity",
        "outputs": [{"internalType": "bytes4", "name": "", "type": "bytes4"}, {
            "internalType": "BalanceDelta",
            "name": "",
            "type": "int256"
        }],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{
            "internalType": "address",
            "name": "",
            "type": "address"
        }, {
            "components": [{
                "internalType": "Currency",
                "name": "currency0",
                "type": "address"
            }, {"internalType": "Currency", "name": "currency1", "type": "address"}, {
                "internalType": "uint24",
                "name": "fee",
                "type": "uint24"
            }, {"internalType": "int24", "name": "tickSpacing", "type": "int24"}, {
                "internalType": "contract IHooks",
                "name": "hooks",
                "type": "address"
            }], "internalType": "struct PoolKey", "name": "", "type": "tuple"
        }, {
            "components": [{"internalType": "bool", "name": "zeroForOne", "type": "bool"}, {
                "internalType": "int256",
                "name": "amountSpecified",
                "type": "int256"
            }, {"internalType": "uint160", "name": "sqrtPriceLimitX96", "type": "uint160"}],
            "internalType": "struct IPoolManager.SwapParams",
            "name": "",
            "type": "tuple"
        }, {"internalType": "BalanceDelta", "name": "", "type": "int256"}, {
            "internalType": "bytes",
            "name": "",
            "type": "bytes"
        }],
        "name": "afterSwap",
        "outputs": [{"internalType": "bytes4", "name": "", "type": "bytes4"}, {
            "internalType": "int128",
            "name": "",
            "type": "int128"
        }],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "owner", "type": "address"}, {
            "internalType": "address",
            "name": "spender",
            "type": "address"
        }, {"internalType": "uint256", "name": "id", "type": "uint256"}],
        "name": "allowance",
        "outputs": [{"internalType": "uint256", "name": "amount", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "spender", "type": "address"}, {
            "internalType": "uint256",
            "name": "id",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "amount", "type": "uint256"}],
        "name": "approve",
        "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "owner", "type": "address"}, {
            "internalType": "uint256",
            "name": "id",
            "type": "uint256"
        }],
        "name": "balanceOf",
        "outputs": [{"internalType": "uint256", "name": "balance", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{
            "internalType": "address",
            "name": "",
            "type": "address"
        }, {
            "components": [{
                "internalType": "Currency",
                "name": "currency0",
                "type": "address"
            }, {"internalType": "Currency", "name": "currency1", "type": "address"}, {
                "internalType": "uint24",
                "name": "fee",
                "type": "uint24"
            }, {"internalType": "int24", "name": "tickSpacing", "type": "int24"}, {
                "internalType": "contract IHooks",
                "name": "hooks",
                "type": "address"
            }], "internalType": "struct PoolKey", "name": "", "type": "tuple"
        }, {
            "components": [{"internalType": "int24", "name": "tickLower", "type": "int24"}, {
                "internalType": "int24",
                "name": "tickUpper",
                "type": "int24"
            }, {"internalType": "int256", "name": "liquidityDelta", "type": "int256"}, {
                "internalType": "bytes32",
                "name": "salt",
                "type": "bytes32"
            }], "internalType": "struct IPoolManager.ModifyLiquidityParams", "name": "", "type": "tuple"
        }, {"internalType": "bytes", "name": "", "type": "bytes"}],
        "name": "beforeAddLiquidity",
        "outputs": [{"internalType": "bytes4", "name": "", "type": "bytes4"}],
        "stateMutability": "pure",
        "type": "function"
    }, {
        "inputs": [{
            "internalType": "address",
            "name": "",
            "type": "address"
        }, {
            "components": [{
                "internalType": "Currency",
                "name": "currency0",
                "type": "address"
            }, {"internalType": "Currency", "name": "currency1", "type": "address"}, {
                "internalType": "uint24",
                "name": "fee",
                "type": "uint24"
            }, {"internalType": "int24", "name": "tickSpacing", "type": "int24"}, {
                "internalType": "contract IHooks",
                "name": "hooks",
                "type": "address"
            }], "internalType": "struct PoolKey", "name": "", "type": "tuple"
        }, {"internalType": "uint256", "name": "", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }, {"internalType": "bytes", "name": "", "type": "bytes"}],
        "name": "beforeDonate",
        "outputs": [{"internalType": "bytes4", "name": "", "type": "bytes4"}],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{
            "internalType": "address",
            "name": "",
            "type": "address"
        }, {
            "components": [{
                "internalType": "Currency",
                "name": "currency0",
                "type": "address"
            }, {"internalType": "Currency", "name": "currency1", "type": "address"}, {
                "internalType": "uint24",
                "name": "fee",
                "type": "uint24"
            }, {"internalType": "int24", "name": "tickSpacing", "type": "int24"}, {
                "internalType": "contract IHooks",
                "name": "hooks",
                "type": "address"
            }], "internalType": "struct PoolKey", "name": "key", "type": "tuple"
        }, {"internalType": "uint160", "name": "", "type": "uint160"}],
        "name": "beforeInitialize",
        "outputs": [{"internalType": "bytes4", "name": "", "type": "bytes4"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{
            "internalType": "address",
            "name": "",
            "type": "address"
        }, {
            "components": [{
                "internalType": "Currency",
                "name": "currency0",
                "type": "address"
            }, {"internalType": "Currency", "name": "currency1", "type": "address"}, {
                "internalType": "uint24",
                "name": "fee",
                "type": "uint24"
            }, {"internalType": "int24", "name": "tickSpacing", "type": "int24"}, {
                "internalType": "contract IHooks",
                "name": "hooks",
                "type": "address"
            }], "internalType": "struct PoolKey", "name": "", "type": "tuple"
        }, {
            "components": [{"internalType": "int24", "name": "tickLower", "type": "int24"}, {
                "internalType": "int24",
                "name": "tickUpper",
                "type": "int24"
            }, {"internalType": "int256", "name": "liquidityDelta", "type": "int256"}, {
                "internalType": "bytes32",
                "name": "salt",
                "type": "bytes32"
            }], "internalType": "struct IPoolManager.ModifyLiquidityParams", "name": "", "type": "tuple"
        }, {"internalType": "bytes", "name": "", "type": "bytes"}],
        "name": "beforeRemoveLiquidity",
        "outputs": [{"internalType": "bytes4", "name": "", "type": "bytes4"}],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{
            "internalType": "address",
            "name": "",
            "type": "address"
        }, {
            "components": [{
                "internalType": "Currency",
                "name": "currency0",
                "type": "address"
            }, {"internalType": "Currency", "name": "currency1", "type": "address"}, {
                "internalType": "uint24",
                "name": "fee",
                "type": "uint24"
            }, {"internalType": "int24", "name": "tickSpacing", "type": "int24"}, {
                "internalType": "contract IHooks",
                "name": "hooks",
                "type": "address"
            }], "internalType": "struct PoolKey", "name": "key", "type": "tuple"
        }, {
            "components": [{"internalType": "bool", "name": "zeroForOne", "type": "bool"}, {
                "internalType": "int256",
                "name": "amountSpecified",
                "type": "int256"
            }, {"internalType": "uint160", "name": "sqrtPriceLimitX96", "type": "uint160"}],
            "internalType": "struct IPoolManager.SwapParams",
            "name": "params",
            "type": "tuple"
        }, {"internalType": "bytes", "name": "", "type": "bytes"}],
        "name": "beforeSwap",
        "outputs": [{"internalType": "bytes4", "name": "", "type": "bytes4"}, {
            "internalType": "BeforeSwapDelta",
            "name": "",
            "type": "int256"
        }, {"internalType": "uint24", "name": "", "type": "uint24"}],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [],
        "name": "checkFeeOn",
        "outputs": [{"internalType": "bool", "name": "feeOn", "type": "bool"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [],
        "name": "checkMarginFeeOn",
        "outputs": [{"internalType": "bool", "name": "feeOn", "type": "bool"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [],
        "name": "feeTo",
        "outputs": [{"internalType": "address", "name": "", "type": "address"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "PoolId", "name": "poolId", "type": "bytes32"}, {
            "internalType": "bool",
            "name": "zeroForOne",
            "type": "bool"
        }, {"internalType": "uint256", "name": "amountOut", "type": "uint256"}],
        "name": "getAmountIn",
        "outputs": [{"internalType": "uint256", "name": "amountIn", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "PoolId", "name": "poolId", "type": "bytes32"}, {
            "internalType": "bool",
            "name": "zeroForOne",
            "type": "bool"
        }, {"internalType": "uint256", "name": "amountIn", "type": "uint256"}],
        "name": "getAmountOut",
        "outputs": [{"internalType": "uint256", "name": "amountOut", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "PoolId", "name": "poolId", "type": "bytes32"}, {
            "internalType": "bool",
            "name": "marginForOne",
            "type": "bool"
        }],
        "name": "getBorrowRate",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "PoolId", "name": "poolId", "type": "bytes32"}, {
            "internalType": "bool",
            "name": "marginForOne",
            "type": "bool"
        }],
        "name": "getBorrowRateCumulativeLast",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [],
        "name": "getHookPermissions",
        "outputs": [{
            "components": [{
                "internalType": "bool",
                "name": "beforeInitialize",
                "type": "bool"
            }, {"internalType": "bool", "name": "afterInitialize", "type": "bool"}, {
                "internalType": "bool",
                "name": "beforeAddLiquidity",
                "type": "bool"
            }, {"internalType": "bool", "name": "afterAddLiquidity", "type": "bool"}, {
                "internalType": "bool",
                "name": "beforeRemoveLiquidity",
                "type": "bool"
            }, {"internalType": "bool", "name": "afterRemoveLiquidity", "type": "bool"}, {
                "internalType": "bool",
                "name": "beforeSwap",
                "type": "bool"
            }, {"internalType": "bool", "name": "afterSwap", "type": "bool"}, {
                "internalType": "bool",
                "name": "beforeDonate",
                "type": "bool"
            }, {"internalType": "bool", "name": "afterDonate", "type": "bool"}, {
                "internalType": "bool",
                "name": "beforeSwapReturnDelta",
                "type": "bool"
            }, {"internalType": "bool", "name": "afterSwapReturnDelta", "type": "bool"}, {
                "internalType": "bool",
                "name": "afterAddLiquidityReturnDelta",
                "type": "bool"
            }, {"internalType": "bool", "name": "afterRemoveLiquidityReturnDelta", "type": "bool"}],
            "internalType": "struct Hooks.Permissions",
            "name": "",
            "type": "tuple"
        }],
        "stateMutability": "pure",
        "type": "function"
    }, {
        "inputs": [{"internalType": "PoolId", "name": "poolId", "type": "bytes32"}, {
            "internalType": "bool",
            "name": "marginForOne",
            "type": "bool"
        }, {"internalType": "uint24", "name": "leverage", "type": "uint24"}],
        "name": "getMarginMax",
        "outputs": [{"internalType": "uint256", "name": "marginMax", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "borrowAmount",
            "type": "uint256"
        }],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "PoolId", "name": "poolId", "type": "bytes32"}, {
            "internalType": "bool",
            "name": "marginForOne",
            "type": "bool"
        }, {"internalType": "uint24", "name": "leverage", "type": "uint24"}, {
            "internalType": "uint256",
            "name": "marginAmount",
            "type": "uint256"
        }],
        "name": "getMarginTotal",
        "outputs": [{
            "internalType": "uint256",
            "name": "marginWithoutFee",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "borrowAmount", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "PoolId", "name": "poolId", "type": "bytes32"}],
        "name": "getReserves",
        "outputs": [{"internalType": "uint256", "name": "_reserve0", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "_reserve1",
            "type": "uint256"
        }],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "PoolId", "name": "poolId", "type": "bytes32"}], "name": "getStatus", "outputs": [{
            "components": [{
                "internalType": "uint128",
                "name": "reserve0",
                "type": "uint128"
            }, {"internalType": "uint128", "name": "reserve1", "type": "uint128"}, {
                "internalType": "uint112",
                "name": "mirrorReserve0",
                "type": "uint112"
            }, {"internalType": "uint112", "name": "mirrorReserve1", "type": "uint112"}, {
                "internalType": "uint32",
                "name": "blockTimestampLast",
                "type": "uint32"
            }, {
                "internalType": "uint256",
                "name": "rate0CumulativeLast",
                "type": "uint256"
            }, {
                "internalType": "uint256",
                "name": "rate1CumulativeLast",
                "type": "uint256"
            }, {
                "components": [{
                    "internalType": "Currency",
                    "name": "currency0",
                    "type": "address"
                }, {"internalType": "Currency", "name": "currency1", "type": "address"}, {
                    "internalType": "uint24",
                    "name": "fee",
                    "type": "uint24"
                }, {
                    "internalType": "int24",
                    "name": "tickSpacing",
                    "type": "int24"
                }, {"internalType": "contract IHooks", "name": "hooks", "type": "address"}],
                "internalType": "struct PoolKey",
                "name": "key",
                "type": "tuple"
            }, {
                "components": [{
                    "internalType": "uint24",
                    "name": "initialLTV",
                    "type": "uint24"
                }, {"internalType": "uint24", "name": "liquidationLTV", "type": "uint24"}, {
                    "internalType": "uint24",
                    "name": "marginFee",
                    "type": "uint24"
                }, {"internalType": "uint24", "name": "protocolFee", "type": "uint24"}, {
                    "internalType": "uint24",
                    "name": "protocolMarginFee",
                    "type": "uint24"
                }], "internalType": "struct FeeStatus", "name": "feeStatus", "type": "tuple"
            }], "internalType": "struct HookStatus", "name": "_status", "type": "tuple"
        }], "stateMutability": "view", "type": "function"
    }, {
        "inputs": [{
            "internalType": "address",
            "name": "sender",
            "type": "address"
        }, {
            "components": [{"internalType": "PoolId", "name": "poolId", "type": "bytes32"}, {
                "internalType": "uint256",
                "name": "amount0",
                "type": "uint256"
            }, {"internalType": "uint256", "name": "amount1", "type": "uint256"}, {
                "internalType": "uint256",
                "name": "tickLower",
                "type": "uint256"
            }, {"internalType": "uint256", "name": "tickUpper", "type": "uint256"}, {
                "internalType": "address",
                "name": "to",
                "type": "address"
            }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}],
            "internalType": "struct AddLiquidityParams",
            "name": "params",
            "type": "tuple"
        }],
        "name": "handleAddLiquidity",
        "outputs": [{"internalType": "uint256", "name": "liquidity", "type": "uint256"}],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{
            "components": [{
                "internalType": "PoolId",
                "name": "poolId",
                "type": "bytes32"
            }, {"internalType": "bool", "name": "marginForOne", "type": "bool"}, {
                "internalType": "uint256",
                "name": "releaseAmount",
                "type": "uint256"
            }], "internalType": "struct LiquidateParams", "name": "params", "type": "tuple"
        }],
        "name": "handleLiquidate",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{
            "components": [{
                "internalType": "PoolId",
                "name": "poolId",
                "type": "bytes32"
            }, {"internalType": "bool", "name": "marginForOne", "type": "bool"}, {
                "internalType": "uint24",
                "name": "leverage",
                "type": "uint24"
            }, {"internalType": "uint256", "name": "marginAmount", "type": "uint256"}, {
                "internalType": "uint256",
                "name": "marginTotal",
                "type": "uint256"
            }, {"internalType": "uint256", "name": "borrowAmount", "type": "uint256"}, {
                "internalType": "uint256",
                "name": "borrowMinAmount",
                "type": "uint256"
            }, {"internalType": "address", "name": "recipient", "type": "address"}, {
                "internalType": "uint256",
                "name": "deadline",
                "type": "uint256"
            }], "internalType": "struct MarginParams", "name": "params", "type": "tuple"
        }],
        "name": "handleMargin",
        "outputs": [{
            "internalType": "uint256",
            "name": "marginWithoutFee",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "borrowAmount", "type": "uint256"}],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{
            "internalType": "address",
            "name": "sender",
            "type": "address"
        }, {
            "components": [{"internalType": "PoolId", "name": "poolId", "type": "bytes32"}, {
                "internalType": "uint256",
                "name": "liquidity",
                "type": "uint256"
            }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}],
            "internalType": "struct RemoveLiquidityParams",
            "name": "params",
            "type": "tuple"
        }],
        "name": "handleRemoveLiquidity",
        "outputs": [{"internalType": "uint256", "name": "amount0", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "amount1",
            "type": "uint256"
        }],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{
            "components": [{
                "internalType": "PoolId",
                "name": "poolId",
                "type": "bytes32"
            }, {"internalType": "bool", "name": "marginForOne", "type": "bool"}, {
                "internalType": "address",
                "name": "payer",
                "type": "address"
            }, {"internalType": "uint256", "name": "repayAmount", "type": "uint256"}, {
                "internalType": "uint256",
                "name": "borrowAmount",
                "type": "uint256"
            }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}],
            "internalType": "struct RepayParams",
            "name": "params",
            "type": "tuple"
        }],
        "name": "handleRepay",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "PoolId", "name": "", "type": "bytes32"}],
        "name": "hookStatusStore",
        "outputs": [{"internalType": "uint128", "name": "reserve0", "type": "uint128"}, {
            "internalType": "uint128",
            "name": "reserve1",
            "type": "uint128"
        }, {"internalType": "uint112", "name": "mirrorReserve0", "type": "uint112"}, {
            "internalType": "uint112",
            "name": "mirrorReserve1",
            "type": "uint112"
        }, {"internalType": "uint32", "name": "blockTimestampLast", "type": "uint32"}, {
            "internalType": "uint256",
            "name": "rate0CumulativeLast",
            "type": "uint256"
        }, {
            "internalType": "uint256",
            "name": "rate1CumulativeLast",
            "type": "uint256"
        }, {
            "components": [{
                "internalType": "Currency",
                "name": "currency0",
                "type": "address"
            }, {"internalType": "Currency", "name": "currency1", "type": "address"}, {
                "internalType": "uint24",
                "name": "fee",
                "type": "uint24"
            }, {"internalType": "int24", "name": "tickSpacing", "type": "int24"}, {
                "internalType": "contract IHooks",
                "name": "hooks",
                "type": "address"
            }], "internalType": "struct PoolKey", "name": "key", "type": "tuple"
        }, {
            "components": [{
                "internalType": "uint24",
                "name": "initialLTV",
                "type": "uint24"
            }, {"internalType": "uint24", "name": "liquidationLTV", "type": "uint24"}, {
                "internalType": "uint24",
                "name": "marginFee",
                "type": "uint24"
            }, {"internalType": "uint24", "name": "protocolFee", "type": "uint24"}, {
                "internalType": "uint24",
                "name": "protocolMarginFee",
                "type": "uint24"
            }], "internalType": "struct FeeStatus", "name": "feeStatus", "type": "tuple"
        }],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{
            "components": [{
                "internalType": "Currency",
                "name": "currency0",
                "type": "address"
            }, {"internalType": "Currency", "name": "currency1", "type": "address"}, {
                "internalType": "uint24",
                "name": "fee",
                "type": "uint24"
            }, {"internalType": "int24", "name": "tickSpacing", "type": "int24"}, {
                "internalType": "contract IHooks",
                "name": "hooks",
                "type": "address"
            }], "internalType": "struct PoolKey", "name": "key", "type": "tuple"
        }], "name": "initialize", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "owner", "type": "address"}, {
            "internalType": "address",
            "name": "operator",
            "type": "address"
        }],
        "name": "isOperator",
        "outputs": [{"internalType": "bool", "name": "isOperator", "type": "bool"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{
            "components": [{
                "internalType": "PoolId",
                "name": "poolId",
                "type": "bytes32"
            }, {"internalType": "bool", "name": "marginForOne", "type": "bool"}, {
                "internalType": "uint256",
                "name": "releaseAmount",
                "type": "uint256"
            }], "internalType": "struct LiquidateParams", "name": "params", "type": "tuple"
        }],
        "name": "liquidate",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "payable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "PoolId", "name": "poolId", "type": "bytes32"}],
        "name": "ltvParameters",
        "outputs": [{"internalType": "uint24", "name": "_initialLTV", "type": "uint24"}, {
            "internalType": "uint24",
            "name": "_liquidationLTV",
            "type": "uint24"
        }],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{
            "components": [{
                "internalType": "PoolId",
                "name": "poolId",
                "type": "bytes32"
            }, {"internalType": "bool", "name": "marginForOne", "type": "bool"}, {
                "internalType": "uint24",
                "name": "leverage",
                "type": "uint24"
            }, {"internalType": "uint256", "name": "marginAmount", "type": "uint256"}, {
                "internalType": "uint256",
                "name": "marginTotal",
                "type": "uint256"
            }, {"internalType": "uint256", "name": "borrowAmount", "type": "uint256"}, {
                "internalType": "uint256",
                "name": "borrowMinAmount",
                "type": "uint256"
            }, {"internalType": "address", "name": "recipient", "type": "address"}, {
                "internalType": "uint256",
                "name": "deadline",
                "type": "uint256"
            }], "internalType": "struct MarginParams", "name": "params", "type": "tuple"
        }],
        "name": "margin",
        "outputs": [{
            "components": [{
                "internalType": "PoolId",
                "name": "poolId",
                "type": "bytes32"
            }, {"internalType": "bool", "name": "marginForOne", "type": "bool"}, {
                "internalType": "uint24",
                "name": "leverage",
                "type": "uint24"
            }, {"internalType": "uint256", "name": "marginAmount", "type": "uint256"}, {
                "internalType": "uint256",
                "name": "marginTotal",
                "type": "uint256"
            }, {"internalType": "uint256", "name": "borrowAmount", "type": "uint256"}, {
                "internalType": "uint256",
                "name": "borrowMinAmount",
                "type": "uint256"
            }, {"internalType": "address", "name": "recipient", "type": "address"}, {
                "internalType": "uint256",
                "name": "deadline",
                "type": "uint256"
            }], "internalType": "struct MarginParams", "name": "", "type": "tuple"
        }],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [],
        "name": "marginPositionManager",
        "outputs": [{"internalType": "contract IMarginPositionManager", "name": "", "type": "address"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [],
        "name": "mirrorTokenManager",
        "outputs": [{"internalType": "contract IMirrorTokenManager", "name": "", "type": "address"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [],
        "name": "owner",
        "outputs": [{"internalType": "address", "name": "", "type": "address"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [],
        "name": "poolManager",
        "outputs": [{"internalType": "contract IPoolManager", "name": "", "type": "address"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [],
        "name": "rateStatus",
        "outputs": [{"internalType": "uint24", "name": "rateBase", "type": "uint24"}, {
            "internalType": "uint24",
            "name": "useHighLevel",
            "type": "uint24"
        }, {"internalType": "uint24", "name": "mLow", "type": "uint24"}, {
            "internalType": "uint24",
            "name": "mHigh",
            "type": "uint24"
        }],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{
            "components": [{
                "internalType": "PoolId",
                "name": "poolId",
                "type": "bytes32"
            }, {"internalType": "uint256", "name": "liquidity", "type": "uint256"}, {
                "internalType": "uint256",
                "name": "deadline",
                "type": "uint256"
            }], "internalType": "struct RemoveLiquidityParams", "name": "params", "type": "tuple"
        }],
        "name": "removeLiquidity",
        "outputs": [{"internalType": "uint256", "name": "amount0", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "amount1",
            "type": "uint256"
        }],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{
            "components": [{
                "internalType": "PoolId",
                "name": "poolId",
                "type": "bytes32"
            }, {"internalType": "bool", "name": "marginForOne", "type": "bool"}, {
                "internalType": "address",
                "name": "payer",
                "type": "address"
            }, {"internalType": "uint256", "name": "repayAmount", "type": "uint256"}, {
                "internalType": "uint256",
                "name": "borrowAmount",
                "type": "uint256"
            }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}],
            "internalType": "struct RepayParams",
            "name": "params",
            "type": "tuple"
        }],
        "name": "repay",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "payable",
        "type": "function"
    }, {
        "inputs": [{
            "internalType": "PoolId",
            "name": "poolId",
            "type": "bytes32"
        }, {
            "components": [{
                "internalType": "uint24",
                "name": "initialLTV",
                "type": "uint24"
            }, {"internalType": "uint24", "name": "liquidationLTV", "type": "uint24"}, {
                "internalType": "uint24",
                "name": "marginFee",
                "type": "uint24"
            }, {"internalType": "uint24", "name": "protocolFee", "type": "uint24"}, {
                "internalType": "uint24",
                "name": "protocolMarginFee",
                "type": "uint24"
            }], "internalType": "struct FeeStatus", "name": "feeStatus", "type": "tuple"
        }], "name": "setFeeStatus", "outputs": [], "stateMutability": "nonpayable", "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "_feeTo", "type": "address"}],
        "name": "setFeeTo",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint24", "name": "_initialLTV", "type": "uint24"}],
        "name": "setInitialLTV",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint24", "name": "_liquidationLTV", "type": "uint24"}],
        "name": "setLiquidationLTV",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "operator", "type": "address"}, {
            "internalType": "bool",
            "name": "approved",
            "type": "bool"
        }],
        "name": "setOperator",
        "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint24", "name": "_fee", "type": "uint24"}],
        "name": "setProtocolFee",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "uint24", "name": "_fee", "type": "uint24"}],
        "name": "setProtocolMarginFee",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "bytes4", "name": "interfaceId", "type": "bytes4"}],
        "name": "supportsInterface",
        "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "receiver", "type": "address"}, {
            "internalType": "uint256",
            "name": "id",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "amount", "type": "uint256"}],
        "name": "transfer",
        "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "sender", "type": "address"}, {
            "internalType": "address",
            "name": "receiver",
            "type": "address"
        }, {"internalType": "uint256", "name": "id", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
        }],
        "name": "transferFrom",
        "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "newOwner", "type": "address"}],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "bytes", "name": "data", "type": "bytes"}],
        "name": "unlockCallback",
        "outputs": [{"internalType": "bytes", "name": "", "type": "bytes"}],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "token", "type": "address"}, {
            "internalType": "address",
            "name": "to",
            "type": "address"
        }, {"internalType": "uint256", "name": "amount", "type": "uint256"}],
        "name": "withdrawFee",
        "outputs": [{"internalType": "bool", "name": "success", "type": "bool"}],
        "stateMutability": "nonpayable",
        "type": "function"
    }]
}