export default {
    "address": "0x6E0EE135EcD02516cB26a8D4f7F7B8dF0b1E748D",
    "abi": [{
        "inputs": [{
            "internalType": "address",
            "name": "initialOwner",
            "type": "address"
        }, {
            "internalType": "contract IPoolManager",
            "name": "_manager",
            "type": "address"
        }, {"internalType": "contract IMarginHookManager", "name": "_hook", "type": "address"}],
        "stateMutability": "nonpayable",
        "type": "constructor"
    }, {"inputs": [], "name": "InsufficientOutputReceived", "type": "error"}, {
        "inputs": [],
        "name": "LockFailure",
        "type": "error"
    }, {"inputs": [], "name": "NotPoolManager", "type": "error"}, {
        "inputs": [],
        "name": "NotSelf",
        "type": "error"
    }, {
        "anonymous": false,
        "inputs": [{"indexed": true, "internalType": "address", "name": "user", "type": "address"}, {
            "indexed": true,
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
        }],
        "name": "OwnershipTransferred",
        "type": "event"
    }, {
        "inputs": [{
            "components": [{
                "internalType": "PoolId",
                "name": "poolId",
                "type": "bytes32"
            }, {"internalType": "bool", "name": "zeroForOne", "type": "bool"}, {
                "internalType": "address",
                "name": "to",
                "type": "address"
            }, {"internalType": "uint256", "name": "amountIn", "type": "uint256"}, {
                "internalType": "uint256",
                "name": "amountOutMin",
                "type": "uint256"
            }, {"internalType": "uint256", "name": "amountOut", "type": "uint256"}, {
                "internalType": "uint256",
                "name": "deadline",
                "type": "uint256"
            }], "internalType": "struct MarginRouter.SwapParams", "name": "params", "type": "tuple"
        }],
        "name": "exactInput",
        "outputs": [{"internalType": "uint256", "name": "amountOut", "type": "uint256"}],
        "stateMutability": "payable",
        "type": "function"
    }, {
        "inputs": [{
            "components": [{
                "internalType": "PoolId",
                "name": "poolId",
                "type": "bytes32"
            }, {"internalType": "bool", "name": "zeroForOne", "type": "bool"}, {
                "internalType": "address",
                "name": "to",
                "type": "address"
            }, {"internalType": "uint256", "name": "amountIn", "type": "uint256"}, {
                "internalType": "uint256",
                "name": "amountOutMin",
                "type": "uint256"
            }, {"internalType": "uint256", "name": "amountOut", "type": "uint256"}, {
                "internalType": "uint256",
                "name": "deadline",
                "type": "uint256"
            }], "internalType": "struct MarginRouter.SwapParams", "name": "params", "type": "tuple"
        }],
        "name": "exactOutput",
        "outputs": [{"internalType": "uint256", "name": "amountIn", "type": "uint256"}],
        "stateMutability": "payable",
        "type": "function"
    }, {
        "inputs": [{
            "internalType": "address",
            "name": "sender",
            "type": "address"
        }, {
            "components": [{"internalType": "PoolId", "name": "poolId", "type": "bytes32"}, {
                "internalType": "bool",
                "name": "zeroForOne",
                "type": "bool"
            }, {"internalType": "address", "name": "to", "type": "address"}, {
                "internalType": "uint256",
                "name": "amountIn",
                "type": "uint256"
            }, {"internalType": "uint256", "name": "amountOutMin", "type": "uint256"}, {
                "internalType": "uint256",
                "name": "amountOut",
                "type": "uint256"
            }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}],
            "internalType": "struct MarginRouter.SwapParams",
            "name": "params",
            "type": "tuple"
        }],
        "name": "handelSwap",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [],
        "name": "hook",
        "outputs": [{"internalType": "contract IMarginHookManager", "name": "", "type": "address"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [],
        "name": "owner",
        "outputs": [{"internalType": "address", "name": "", "type": "address"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [],
        "name": "poolManager",
        "outputs": [{"internalType": "contract IPoolManager", "name": "", "type": "address"}],
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "newOwner", "type": "address"}],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "bytes", "name": "data", "type": "bytes"}],
        "name": "unlockCallback",
        "outputs": [{"internalType": "bytes", "name": "", "type": "bytes"}],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address", "name": "token", "type": "address"}, {
            "internalType": "address",
            "name": "to",
            "type": "address"
        }, {"internalType": "uint256", "name": "amount", "type": "uint256"}],
        "name": "withdrawFee",
        "outputs": [{"internalType": "bool", "name": "success", "type": "bool"}],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {"stateMutability": "payable", "type": "receive"}]
}